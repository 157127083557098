export const getDefaultChartOptions = (handlePointClick) => ({
    yAxis: [
        {
            floor: 0,
        },
        {
            height: "80%",
        },
        {
            top: "80%",
            height: "20%",
            offset: 0,
            min: 0,
        },
    ],
    xAxis: [{
        crosshair: false,
        ordinal: false,
    }],
    chart: {
        animation: true,
        height: '65%',
        zIndex: 0
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }
        ]
    },
    series: [
        {
            name: 'Price',
            findNearestPointBy: 'xy',
            zIndex: 100,
            lineWidth: 2,
            color: "black",
            // decimal points should be max 2
            tooltip: {
                valueDecimals: 2,
            },
        },
        {
            name: 'EPS × Multiple',
            findNearestPointBy: 'xy',
            type: 'areaspline',
            lineColor: "orange",
            lineWidth: 4,
            color: "green",
            tooltip: {
                valueDecimals: 2,
            },
            marker: {
                enabled: true,
                radius: 5,
                symbol: 'triangle',
                fillColor: 'white',
                lineColor: 'black',
                lineWidth: 1,
            },
        },
        {
            name: "performance",
            type: "line",
            dashStyle: 'LongDash',
            lineColor: "darkgrey",
            enableMouseTracking: false,
            marker: {
                enabled: true,
                radius: 5,
                symbol: 'circle',
                fillColor: 'red',
                lineColor: 'red',
                lineWidth: 1,
            },
        }
    ],
    tooltip: {
        shared: true,
    },
    plotOptions: {
        series: {
            states: {
                hover: { lineWidthPlus: 0 },
                inactive: { enabled: false },
            },
            point: {
                events: {
                    click: handlePointClick
                }
            }
        }
    },
    rangeSelector: {
        selected: 3,
        buttons: [
            {
                type: 'year',
                count: 5,
                text: '5y'
            },
            {
                type: 'year',
                count: 10,
                text: '10y'
            },
            {
                type: 'year',
                count: 15,
                text: '15y'
            },
            {
                type: 'year',
                count: 20,
                text: '20y'
            },
            {
                type: 'year',
                count: 25,
                text: '25y'
            },
            {
                type: 'year',
                count: 30,
                text: '30y'
            },
            {
                type: 'all',
                text: 'All'
            }
        ]
    },
});

// Utility: Debounce function
const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

// List of excluded exchanges
const EXCLUDED_EXCHANGES = [
    'CBOE',
    'New York Stock Exchange Arca',
    'NASDAQ Global Market'
];

// Check if name contains ETF/ETC/ETP
const isETProduct = (name) => {
    const etProducts = ['etf', 'etc', 'etp', 'fund'];
    return etProducts.some(product => name.toLowerCase().includes(product));
};

// Fetch stock data from the API
const fetchStockData = async (searchTerm, API_BASE_URL, API_KEY) => {
    try {
        const response = await fetch(
            `${API_BASE_URL}/search?query=${encodeURIComponent(searchTerm)}&limit=25&apikey=${API_KEY}`
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('API call failed:', error);
        return [];
    }
};

// Create the handler for symbol changes
export const createHandleSymbolChange = (setSymbol, setSuggestions, API_BASE_URL, API_KEY) => {
    const debouncedApiCall = debounce(async (value) => {
        if (value.trim()) {
            try {
                const data = await fetchStockData(value.trim(), API_BASE_URL, API_KEY);
                console.log(value);

                // Validate, process, and sort the data
                const filtered = data
                    .filter(item =>
                        item &&
                        typeof item.symbol === 'string' &&
                        typeof item.name === 'string' &&
                        !EXCLUDED_EXCHANGES.includes(item.exchangeShortName) &&
                        !isETProduct(item.name)
                    )
                    .map(item => ({
                        symbol: item.symbol,
                        name: item.name,
                        exchange: item.exchange || item.exchangeShortName || ''
                    }))
                    .sort((a, b) => a.symbol.replace('.','').length - b.symbol.replace('.','').length) // Sort by length of symbol (excluding dots) ascending
                    .slice(0, 25); // Limit to 25 suggestions
                if (filtered.length > 0) {
                    setSuggestions(filtered);
                } else {
                    // Default suggestion if no valid suggestions found
                    setSuggestions([{
                        symbol: 'N/A',
                        name: 'No results found. Try a different search term.',
                        exchange: ''
                    }]);
                }
            } catch (error) {
                console.error('Error processing suggestions:', error);
                setSuggestions([{
                    symbol: 'N/A',
                    name: 'Unable to fetch suggestions. Please try again later.',
                    exchange: ''
                }]);
            }
        } else {
            setSuggestions([]);
        }
    }, 500); // 500ms debounce delay for API calls

    // Return a combined handler
    return (event) => {
        const value = event.target.value;
        setSymbol(value); // Update the symbol immediately for UI purposes
        debouncedApiCall(value); // Debounce API calls for suggestions
    };
};

export const createPointClickHandler = (
    pointCountRef,
    setAfter,
    setBefore,
    setChangeAnn,
    setPointCount
) => {
    return function(e) {
        try {
            const chart = e.point.series.chart;
            const series = chart.series[2];

            if (!series) return;

            const eventPoint = e.point

            const y = eventPoint.y;
            const x = eventPoint.x;

            if (pointCountRef.current === 2) {
                while (series.points.length > 0) {
                    series.removePoint(0);
                }
                setAfter(null);
                setBefore(null);
                setChangeAnn(null);
                pointCountRef.current = 0;
                setPointCount(0);
            } else if (pointCountRef.current === 1) {
                setAfter(y);
                series.addPoint([x, y]);
                pointCountRef.current = 2;
                setPointCount(2);
                // calculate annualised performance between the first two points in seriesPerformance
                console.log(series && series.points && series.points.length >= 2)
                if (series && series.points && series.points.length >= 2) {

                    // const point1 = series.points[0]; if  .x and .y of series.points[0] is not empty or undefined else series.points[1]
                    const point1 = series.points[0].x && series.points[0].y ? series.points[0] : series.points[1];

                    // Calculate time difference in years
                    const timeDiff = (x - point1.x) / (365 * 24 * 60 * 60 * 1000); // Convert milliseconds to years

                    console.log(timeDiff)
        
                    // Calculate annualized return using the formula: ((end/start)^(1/years) - 1) * 100
                    const annualizedReturn = (Math.pow(y / point1.y, 1 / timeDiff) - 1) * 100;
        
                    // You can store this value in state or use it as needed
                    // print x,y from point1 and point2
                    console.log(point1.x, point1.y, x, y, annualizedReturn);

                    //set to zero if nan
                    if (isNaN(annualizedReturn)) {
                        setChangeAnn(0);
                    } else {
                        setChangeAnn(annualizedReturn);
                    }
                }
            } else {
                setBefore(y);
                series.addPoint([x, y]);
                pointCountRef.current = 1;
                setPointCount(1);
            }
        } catch (error) {
            console.error('Error in click handler:', error);
        }
    };
};

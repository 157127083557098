import React, { useState, useEffect, useRef } from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DragPanes from "highcharts/modules/drag-panes";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import PriceIndicator from "highcharts/modules/price-indicator";
import FullScreen from "highcharts/modules/full-screen";
import StockTools from "highcharts/modules/stock-tools";

import { fetchChartData, updateChartData } from './data/dataService';
import { getDefaultChartOptions } from './config/chartConfig';
import { createPointClickHandler } from './handlers/handlePointClick';
import { createHandleSymbolChange } from './handlers/handleSymbolChange';
import './Funviz.css';

DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);

const FunViz = () => {

    const [symbol, setSymbol] = useState('AAPL');
    const [companyNameActive, setCompanyNameActive] = useState('Apple Inc.');
    const [companyName, setCompanyName] = useState('Apple Inc.');
    const [symbolActive, setSymbolActive] = useState('AAPL');
    const [multiple, setMultiple] = useState(15);
    const [change, setChange] = useState(0);
    const [changeAnn, setChangeAnn] = useState(0);
    const [before, setBefore] = useState(0);
    const [after, setAfter] = useState(0);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [pointCount, setPointCount] = useState(0);
    const pointCountRef = useRef(0);

    const cache = useRef({});
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);
    const inputQueue = useRef([]);
    const queueTimer = useRef(null);

    const API_KEY = 'a53cdaeb4ea42936853813db293a1047'
    const API_BASE_URL = 'https://financialmodelingprep.com/api/v3'
    const url = symbol && `https://aubkfxjk61.execute-api.eu-central-1.amazonaws.com/prod/main/${symbol}`;

    // Create the debounced handler
    const handleSymbolChange = createHandleSymbolChange(setSymbol, setSuggestions, API_BASE_URL, API_KEY);

    const handleInputChange = (event) => {
        // Add the new event to the queue
        setSymbol(event.target.value);  // Update symbol immediately
        inputQueue.current.push(event);

        // If this is the first item in the queue, start the timer
        if (inputQueue.current.length === 1) {
            queueTimer.current = setTimeout(() => {
                // After 5 seconds, process the last event in the queue
                if (inputQueue.current.length > 0) {
                    const lastEvent = inputQueue.current[inputQueue.current.length - 1];
                    handleSymbolChange(lastEvent);
                    // Clear the queue
                    inputQueue.current = [];
                }
                queueTimer.current = null;
            }, 3000);
        }
    };

    const handlePointClick = createPointClickHandler(
        pointCountRef,
        setAfter,
        setBefore,
        setChangeAnn,
        setPointCount,
    );

    const [chartOptions, setChartOptions] = useState(getDefaultChartOptions(handlePointClick));

    const fetchData = async () => {
        // initialize variable data with empty series for highcharts plot
        var data = {
            price: [],
            fin: []
        };

        function applyMultiple(series) {
            var applyMultiple = function (x) {
                return [x[0], x[1] * multiple];
            }

            var newArray = series.map(applyMultiple);
            return newArray
        }
        var result = [];
        setStatus('fetching');
        console.log(`checking cache ${Math.floor(Math.random() * 100)}`)
        if (cache.current[symbol]) {
            console.log("fetching cache")
            var data = cache.current[symbol];
            setData(data);
            setStatus('fetched');
            result = data;
            // result["fin"] = applyMultiple(data["fin"])
        } else {
            console.log("fetching api")
            await fetch(url)
                .then(response => response.json())
                .then(data => {
                    cache.current[symbol] = data;
                    setData(data);
                    setStatus('fetched');
                    result = data;
                    // result["fin"] = applyMultiple(data["fin"])
                })
                .catch((err) => {
                    console.log(`Error fetching data for ${symbol}`)
                    // setData(data);
                    setStatus('error');
                    result = data;
                });
        }
        setChartOptions({
            series: [
                { data: result["price"] },
                { data: applyMultiple(result["fin"]) },
                { data: [[]] }
            ]
        })
        setSymbolActive(symbol);
        setCompanyNameActive(companyName);
    };

    const handleMultipleChange = event => {
        setMultiple(event.target.value);

        console.log('value is:', event.target.value);
    };

    const handleClick = event => {
        event.preventDefault();
        setSymbol(symbol);
        // 👇️ value of input field
        console.log('fetching for 👉️', symbol);
        const { status, data, error } = fetchData();
    };

    useEffect(() => {
        const { status, data, error } = fetchData();
    }, []);

    useEffect(() => {
        var change = ((after - before) / before * 100).toFixed(2);
        if (isNaN(change)) {
            change = 0;
        }
        setChange(change);
    }, [after]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.input-field')) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Clean up timer on component unmount
    useEffect(() => {
        return () => {
            if (queueTimer.current) {
                clearTimeout(queueTimer.current);
            }
        };
    }, []);

    return (
        <div className="container">
            {/* <header>
                <h2 className="title">FunViz</h2>
            </header> */}

            <main>
                <section className="chart-section">
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="stockChart"
                        options={chartOptions}
                    />
                </section>

                <section className="controls-section">
                    <div className="input-controls">
                        <div className="input-field">
                            <input
                                type="text"
                                value={symbol}
                                onChange={handleInputChange}

                                // onChange={}
                                placeholder={symbol}
                                onFocus={() => setShowSuggestions(true)}
                            />
                            {showSuggestions && suggestions.length > 0 && (
                                <div className="suggestions-container">
                                    {suggestions.map((item, index) => (
                                        <div
                                            key={index}
                                            className="suggestion-item"
                                            onClick={() => {
                                                setSymbol(item.symbol);
                                                setCompanyName(item.name);
                                                setSuggestions([]);
                                                setShowSuggestions(false);
                                            }}
                                        >
                                            <span className="exchange-symbol">
                                                <span className="exchange">{item.exchange}:</span>
                                                <span className="symbol">{item.symbol}</span>
                                            </span>
                                            <span className="name">{item.name}</span>


                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>


                        <div className="input-field">
                            <input
                                type="text"
                                id="multiple"
                                name="multiple"
                                value={multiple}
                                onChange={handleMultipleChange}
                                placeholder="Enter multiple"
                                autoComplete="off"
                            />
                        </div>

                        <button
                            className="update-button"
                            onClick={handleClick}
                        >
                            Update
                        </button>
                    </div>

                    <div className="statistics">
                            <div className="company-card">
                                <div className="company-card-header">
                                    <h2 className="company-name">{companyNameActive}</h2>
                                    <span className="company-symbol">{symbolActive.toUpperCase()}</span>
                                </div>
                                <div className="company-card-body">
                                    <div className="detail-item">
                                        <span className="detail-label">Multiple</span>
                                        <span className="detail-value">{multiple}</span>
                                    </div>
                                    {/* You can add more detail items here if needed */}
                                </div>
                            </div>




                        <div className="secondary-stats">
                            <div className="stat-grid">
                                <div className="stat-item">
                                    <span className="label">Change</span>
                                    <span className="value">{Number(change).toFixed(2)}%</span>
                                </div>
                                <div className="stat-item change-ann">
                                    <span className="label">Change Ann.</span>
                                    <span className="value">{Number(changeAnn).toFixed(2)}% p.a.</span>
                                </div>
                                <div className="stat-item">
                                    <span className="label">Before</span>
                                    <span className="value">{Number(before).toFixed(2)}</span>
                                </div>
                                <div className="stat-item">
                                    <span className="label">After</span>
                                    <span className="value">{Number(after).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
            </main>
        </div>

    );
}

export default FunViz;

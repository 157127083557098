import { Outlet, Link } from "react-router-dom";
import "./Layout.css"; // Import the CSS file

const Layout = () => {
  return (
    <>
      <nav className="collapsible-navbar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/funviz">FunViz</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li style={{ float: "right" }}>
            <Link to="/" className="active">tobigs.de</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </>
  );
};

export default Layout;
